import { Button, Card, Col, Descriptions, Empty, Form, Image, Input, Modal, Row, Select, Spin, Table, message } from "antd";
import { UploadFile } from 'antd/es/upload';
import { useForm } from "antd/lib/form/Form";
import hintIcon from 'assets/image/icon-12.png';
import { GATEWAY_CODE_WITHDRAW } from "constants/gateway";
import dayjs from "dayjs";
import { AGENT_LOG } from "enum/cashlog";
import { AGENT_RELATION, ENABLE, TRANSFER_TYPE } from "enum/state";
import useAccount, { AccountInfo } from "hooks/account.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get, $post } from "services";
import { toFormatNumber as $f, $mobile, enumToOptions, timeL2S, timeS2L, toFormatNumber, verify } from "utils/common";
import { handleTableChange } from 'utils/table';
import { RESPONSE_CODE } from "../constants/response";
import { CopyButton, DatePickerCol, UploadImage } from "./layout.component";

export enum WITHDRAW_GATEWAY_ID {
  bank = "WITHDRAW_GATE_BANK",
  virtual = "WITHDRAW_GATE_CRYPTO",
}

// 新增收款方式
export const PopupWithdrawMethodModal = ({ isOpen, close, mutate }: any) => {
  const { data: $s, bankCode2Name } = useSite();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [inputPhone, setInputPhone] = useState('');
  const [cryptoType, setCryptoType] = useState<any | null>();
  const { data: cryptoList } = $get({ url: 'api/withdrawmethod/crypto/list' })
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const { data: MerchantList } = $get({
    url: 'api/withdrawmethod/crypto/merchant/list',
    params: { Crypto: cryptoType },
    allow: !!cryptoType
  })
  // 通道列表
  const [paymentMethod, setPaymentMethod] = useState<any | null>();
  const { data: _gatewayList } = $get({
    url: 'api/gateway/list',
    params: {
      Type: 1,
      IsOffline: 0
    }
  })
  // 銀行名稱列表
  const { data: _getBankNameList } = $get({
    url: `api/bank/${$s?.Currency}/list`,
    allow: !!($s?.Currency)
  })

  const [form] = Form.useForm();
  const handleSubmit = async (formData: any) => {
    if (paymentMethod === WITHDRAW_GATEWAY_ID.bank) {
      $post({
        url: 'api/withdrawmethod/bank/create',
        send: {
          GatewayId: _gatewayList.Data.filter((item: any) => item.Code === formData.GatewayId)[0].Id,
          BankCode: formData.BankCode,
          BankName: bankCode2Name(formData.BankCode),
          Account: formData.Account,
          Name: formData.Name,
          Branch: formData.Branch,
          State: formData.State || null,
          City: formData.City || null,
          PhoneNumber: inputPhone || null,
          PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : null
        },
        success: () => {
          handleCancel();
          mutate();
          message.success(i18n.t('addSuccess'));
        }
      })
    } else if (paymentMethod === WITHDRAW_GATEWAY_ID.virtual) {
      $post({
        url: 'api/withdrawmethod/crypto/create',
        send: {
          GatewayId: _gatewayList.Data.filter((item: any) => item.Code === formData.GatewayId)[0].Id,
          MerchantId: currentMerchant.WithdrawMerchantId,
          Crypto: formData.Crypto,
          CryptoType: currentMerchant.CryptoType,
          CryptoAddress: formData.CryptoAddress,
          Photo: imageData[0] ? [imageData[0].url] : []
        },
        success: () => {
          handleCancel();
          mutate();
          message.success(i18n.t('addSuccess'));
        }
      })
    }
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    setInputPhone('');
    setPaymentMethod(null);
    setCryptoType(null)
    setCurrentMerchant({});
    setImageData([]);
  };

  const handlePhoneNumber = (value: string) => {
    setInputPhone(value);
  }

  return (
    <Modal
      title={i18n.t('addPaymentMethod')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" className="mt-1" gutter={[10, 10]}>
          <Col><Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]}>

          <Col span={24}>
            <Form.Item name="GatewayId" label={i18n.t('paymentMethod')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                placeholder={`${i18n.t('pleaseSelect')}`}
                options={_gatewayList?.Data.map((item: any) => {
                  return { value: item.Code, label: i18n.t(item.Code) }
                })}
                onChange={setPaymentMethod}
              />
            </Form.Item>
          </Col>

          {paymentMethod === WITHDRAW_GATEWAY_ID.bank && <>
            <Col span={24}>
              <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  showSearch
                  filterOption={(input, option) =>
                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) ||
                    `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder={`${i18n.t('inputData')}`}
                  options={_getBankNameList?.Data.map((item: any) => {
                    return { value: item.Code, label: i18n.t(item.Name) }
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Account" label={i18n.t('accountNumber')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Name" label={i18n.t('bankAccountName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            {
              $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
                : <>
                  <Col span={24}>
                    <Form.Item name="Branch" label={i18n.t('openingBranch')} rules={[{ required: true }]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="State" label={i18n.t('bankCounty')}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="City" label={i18n.t('city')}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={10}>
                        <Form.Item name="PhonePrefix" label={i18n.t('phoneNumber')}>
                          <Input placeholder={`${i18n.t('enterCountryCode')}`} addonBefore={'+'} />
                        </Form.Item>
                      </Col>
                      <Col span={14}>
                        <Form.Item label={' '} >
                          <Input placeholder={`${i18n.t('inputMobile')}`}
                            value={inputPhone}
                            onChange={e => handlePhoneNumber(e.target.value)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </>
            }
          </>}

          {paymentMethod === WITHDRAW_GATEWAY_ID.virtual && <>
            <Col span={24}>
              <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={cryptoList?.Data?.map((item: any) => {
                    return { value: item.Crypto, label: item.Crypto }
                  })}
                  onChange={val => {
                    setCryptoType(val);
                    form.setFieldValue('CryptoId', null);
                    setCurrentMerchant({});
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} >
              <Form.Item name="CryptoId" label={i18n.t('networkType')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={MerchantList?.Data
                    ?.filter((item: any) => item.Status === ENABLE.enabled)
                    ?.map((item: any) => {
                      return { value: item.Id, label: item.CustomName }
                    })}
                  onChange={val => {
                    setCurrentMerchant(MerchantList?.Data?.find((item: any) => item.Id === val))
                  }}
                />
              </Form.Item>
              <Form.Item className="display-none" name="MerchantId" />
            </Col>
            <Col span={24} >
              <Form.Item name="CryptoAddress" label={i18n.t('userAddress')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24} >
              <Form.Item name="Photo" label={i18n.t('qrCode')}>
                <UploadImage
                  name="Photo"
                  url={'/upload/images/closed/deposit'}
                  imageData={imageData}
                  setImageData={setImageData}
                />
              </Form.Item>
            </Col>
            <Col span={24} >
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                {i18n.t('allowedAndSizeNotExceed500kb')}
              </div>
            </Col>
          </>}

        </Row>
      </Form>
    </Modal>
  )
}

// 提款申請
export const PopupWithdrawModal = ({ isOpen, close, form, setBankcard }: any) => {
  const formData = form.getFieldsValue();
  const [loading, setLoading] = useState(false);
  const withdrawmethodFormSubmit = () => {
    setLoading(true);
    const send = {
      WithdrawMethodId: formData.WithdrawMethodId,
      Amount: formData.Amount,
    };
    $post({
      url: 'api/withdrawticket/agent/create',
      send,
      success: () => {
        setBankcard({});
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
      }
    })
  };

  const handleCancel = () => {
    close(false);
    form.resetFields();
  };

  return (
    <Modal
      title={
        <Row gutter={[12, 12]} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      styles={{
        body: {
          height: '30px',
        }
      }}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={handleCancel}>
              {i18n.t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false} onClick={withdrawmethodFormSubmit}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <span>{`${i18n.t('withdrawalAmount')}：${formData.Amount}`}</span>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

// 存款銀行卡
export const PopupOfflineDepositModal = ({ isOpen, close }: any) => {
  const { data: $s } = useSite();
  const [form] = Form.useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  // 銀行卡列表
  const [depositMerchantList, setDepositMerchantList] = useState<any>({});
  const { data: _depositMerchantList } = $get({
    url: 'api/deposit/merchant/list',
    params: { GatewayCode: 'DEPOSIT_GATE_BANK_OFFLINE' }
  })
  useEffect(() => {
    if (_depositMerchantList) {
      const obj: any = {};

      _depositMerchantList.Data.forEach((item: any) => {
        obj[item.DepositMerchantId] = { ...obj[item.DepositMerchantId], ...item }
      })
      setDepositMerchantList(obj)
    }
  }, [_depositMerchantList]);

  const [currentDepositId, setCurrentDepositId] = useState(0);
  const updateBankcard = (value: any) => {
    // const bankData = _depositMerchantList.Data.find((item: any) => item.DepositMerchantId === value);
    // setBankcard(bankData);
    setCurrentDepositId(value)
  }

  const [bankcard, setBankcard] = useState<any>({});
  const { data: one, isValidating } = $get({
    url: 'api/deposit/merchant/one',
    params: { Id: currentDepositId },
    allow: !!currentDepositId
  })
  useEffect(() => {
    if (one) {
      setBankcard({
        ...one.Data,
        ...one.Data.Offline
      })
    }
  }, [one]);
  const [loading, setLoading] = useState(false);
  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);
    const send = {
      OfflineDepositId: bankcard.OfflineDepositId,
      DepositMerchantId: formData.DepositMerchantId,
      Amount: formData.Amount,
      Remark: formData.Remark,
      Photo: imageData[0] ? [imageData[0].url] : [],
    };
    $post({
      url: 'api/deposit/create',
      send,
      success: () => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')} ${bankcard.Minimum} ~ ${bankcard.Maximum || i18n.t('unlimited')}`)
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    setBankcard({})
    setImageData([]);
    setCurrentDepositId(0)
  }

  return (
    <Modal
      title={i18n.t('WITHDRAW_GATE_BANK')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={$mobile ? '80vw' : '900px'}
      footer={
        <Row justify="center" gutter={[12, 12]} className="mt-1" style={{ paddingRight: '16px' }}>
          <Col><Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" disabled={loading} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <Row gutter={[12, 12]} className="w-full">
            <Col span={$mobile ? 24 : 12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item name="DepositMerchantId" label={i18n.t('bankName')} rules={[{ required: true }]}>
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      options={
                        Object.keys(depositMerchantList).map((k: any) => ({
                          value: k, label: depositMerchantList[k].CustomName
                        }))
                      }
                      onChange={updateBankcard}
                    />
                  </Form.Item>
                </Col>
                {/* 基本資料 */}
                <Col span={24}>
                  <Spin spinning={isValidating}>
                    <Descriptions size="small" layout={$mobile ? 'vertical' : 'horizontal'} column={1} bordered
                      labelStyle={{ fontSize: 12, fontWeight: 600, minWidth: 170, width: 170 }}>
                      <Descriptions.Item label={i18n.t('bankName')}>
                        <Row align="middle" justify="space-between">
                          <Col>{bankcard.BankName}</Col>
                          <Col><CopyButton text={bankcard.BankName} /></Col>
                        </Row>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('bankAccount')}>
                        <Row align="middle" justify="space-between">
                          <Col>{bankcard.Account}</Col>
                          <Col><CopyButton text={bankcard.Account} /></Col>
                        </Row>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('bankAccountName')}>
                        <Row align="middle" justify="space-between">
                          <Col>{bankcard.Name}</Col>
                          <Col><CopyButton text={bankcard.Name} /></Col>
                        </Row>
                      </Descriptions.Item>
                      {
                        $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
                          : <>
                            <Descriptions.Item label={i18n.t('province')}>
                              <Row align="middle" justify="space-between">
                                <Col>{bankcard.State}</Col>
                                <Col><CopyButton text={bankcard.State} /></Col>
                              </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label={i18n.t('city')}>
                              <Row align="middle" justify="space-between">
                                <Col>{bankcard.City}</Col>
                                <Col><CopyButton text={bankcard.City} /></Col>
                              </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label={i18n.t('branchName')}>
                              <Row align="middle" justify="space-between">
                                <Col>{bankcard.Branch}</Col>
                                <Col><CopyButton text={bankcard.Branch} /></Col>
                              </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label={i18n.t('phoneNumber')}>
                              <Row align="middle" justify="space-between">
                                <Col>{`${bankcard.PhonePrefix || ''}${bankcard.PhoneNumber || ''}`}</Col>
                                <Col><CopyButton text={`${bankcard.PhonePrefix || ''}${bankcard.PhoneNumber || ''}`} /></Col>
                              </Row>
                            </Descriptions.Item>
                          </>
                      }
                      <Descriptions.Item label={i18n.t('remark')}>
                        {bankcard.FnDescription}
                      </Descriptions.Item>
                      {bankcard.QR_Code ?
                        <Descriptions.Item label={i18n.t('qrCode')}>
                          <Image src={bankcard.QR_Code} width={80} height={80} />
                        </Descriptions.Item> : null
                      }
                    </Descriptions>
                  </Spin>
                </Col>
              </Row>
            </Col>
            <Col span={$mobile ? 24 : 12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                  <div className="color-05 size-10">
                    {currentDepositId ? `${i18n.t('single')}: ${bankcard.Minimum || 0} - ${bankcard.Maximum || i18n.t('unlimited')}` : ''}
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('uploadCertification')}
                    rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/closed/deposit'}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="color-03 size-12">
                    {i18n.t('onlyJpgOrPngFiles')}
                    {i18n.t('allowedAndSizeNotExceed500kb')}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 存款虛擬貨幣
export const PopupOnlineBankingModal = ({ isOpen, close }: any) => {
  const [form] = Form.useForm();
  const [cryptoType, setCryptoType] = useState<any | null>();
  const { data: cryptoList } = $get({ url: 'api/deposit/offline/crypto/list' });
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const [amount, setAmount] = useState(0);
  const { data: MerchantList } = $get({
    url: 'api/deposit/offline/crypto/merchant/list',
    params: { Crypto: cryptoType },
    allow: !!cryptoType
  })
  const [loading, setLoading] = useState(false);
  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);
    const send = {
      OfflineDepositId: currentMerchant.Id,
      DepositMerchantId: formData.DepositMerchantId,
      Amount: formData.Amount,
      Remark: '',
      Photo: [],
    };
    $post({
      url: 'api/deposit/create',
      send,
      success: () => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')} ${currentMerchant.Minimum} ~ ${currentMerchant.Maximum || i18n.t('unlimited')}`)
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    // setPaymentMethod(null);
    setCryptoType(null)
    setCurrentMerchant({});
    // setImageData([]);
  };

  return (
    <Modal
      title={i18n.t('WITHDRAW_GATE_CRYPTO')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]}>
            {/* 幣種 */}
            <Col span={24}>
              <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={cryptoList?.Data?.map((item: any) => {
                    return { value: item.Crypto, label: item.Crypto }
                  })}
                  onChange={val => {
                    setCryptoType(val);
                    form.setFieldValue('CryptoType', null);
                    form.setFieldValue('DepositMerchantId', null);
                    setCurrentMerchant({});
                  }}
                />
              </Form.Item>
            </Col>
            {/* 網路類型 */}
            <Col span={24} >
              <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={
                    MerchantList?.Data
                      ?.filter((item: any, i: any) => (MerchantList.Data.map((_: any) => _.CryptoType).indexOf(item.CryptoType)) === i)
                      ?.filter((item: any) => item.Status === ENABLE.enabled)
                      .map((item: any) => {
                        return { value: item.CryptoType, label: item.CustomName }
                      })
                  }
                  onChange={val => {
                    form.setFieldValue('DepositMerchantId', null);
                    setCurrentMerchant({});
                  }}
                />
              </Form.Item>
              <Form.Item className="display-none" name="MerchantId" />
            </Col>
            {/* 地址 */}
            <Col span={24} >
              <Form.Item name="DepositMerchantId" label={i18n.t('userAddress')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={
                    MerchantList?.Data?.filter((item: any) => item.CryptoType === form.getFieldValue('CryptoType'))
                      .map((item: any) => {
                        return { value: item.DepositMerchantId, label: item.CryptoAddress }
                      })
                  }
                  onChange={val => {
                    setCurrentMerchant(MerchantList?.Data?.find((item: any) => item.DepositMerchantId === val))
                  }}
                />
              </Form.Item>
              <Form.Item className="display-none" name="MerchantId" />
            </Col>
            {currentMerchant.QR_Code &&
              <Col span={24} >
                <Image className="center" src={currentMerchant.QR_Code} width={100} height={100} />
              </Col>
            }
            <Col span={24}>
              <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
                <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setAmount(Number(e.target.value))} />
              </Form.Item>
              <div className="color-05 size-12">
                {currentMerchant.Maximum ? `${i18n.t('single')}: ${currentMerchant.Minimum} - ${currentMerchant.Maximum}` : ''}
              </div>
              <div className="color-05 size-12">
                {i18n.t('exchangeRate')}: <span className="color-07">≈ {toFormatNumber(Number(amount) / currentMerchant.ExchangeRate, 2, 'ceil')} {cryptoType}</span>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 存款三方
export const PopupPaymayaModal = ({ isOpen, close, currentManufacturer }: any) => {
  const [maximum, setMaximum] = useState('');
  const [minimum, setMinimum] = useState('');
  const [form] = Form.useForm();
  const { data: merchantList } = $get({
    url: 'api/deposit/merchant/list',
    params: { GatewayCode: currentManufacturer.Code },
    allow: !!currentManufacturer.Code
  })
  const { data: $s } = useSite();
  // 銀行名稱列表
  const { data: _getBankNameList } = $get({
    url: `api/bank/${$s?.Currency}/list`,
    allow: !!($s?.Currency)
  })
  const [loading, setLoading] = useState(false);
  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);
    const send = {
      OfflineDepositId: null,
      DepositMerchantId: formData.Name,
      Amount: formData.Amount,
      Remark: '',
      Photo: [],
      BankCode: formData.BankCode
    };
    $post({
      url: 'api/deposit/create',
      send,
      showMessage: false,
      success: (response: any) => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
        window.open(response?.Url)
      },
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')}${minimum}~${maximum || i18n.t('unlimited')}`)
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    setMaximum('');
    setMinimum('');
  };

  const onAmountLimit = (value: any) => {
    if (value) {
      const selectedMerchant = merchantList?.Data.find((item: any) => item.DepositMerchantId === value);
      if (selectedMerchant) {
        setMaximum(selectedMerchant.Maximum)
        setMinimum(selectedMerchant.Minimum)
      }
    }
  }

  return (
    <Modal
      title={`${currentManufacturer.Name}`}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="Name" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={merchantList?.Data.map((item: any) => {
                    return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
                  })}
                  onChange={onAmountLimit}
                />
              </Form.Item>
            </Col>
            {(merchantList?.Data[0]?.GatewayCode !== "DEPOSIT_GATE_CVS") && ($s && ($s.Currency === 'VND' || $s.Currency === 'kVND'))
              &&
              <Col span={24}>
                <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
                  <Select
                    className="w-full"
                    showSearch
                    filterOption={(input, option) =>
                      `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) ||
                      `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={`${i18n.t('inputData')}`}
                    options={_getBankNameList?.Data.map((item: any) => {
                      return { value: item.Code, label: i18n.t(item.Name) }
                    })}
                  />
                </Form.Item>
              </Col>
            }
            <Col span={24}>
              <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
              <div className="color-05 size-10">
                {merchantList ? `${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}` : ''}
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 確認轉帳
export const PopupTransferModal = ({ isOpen, close, clear, getFormValues, mutate, accountType }: any) => {

  useEffect(() => {
    // 取得外部表單資料
    if (isOpen) {
      const data = getFormValues()
      form.setFieldsValue({
        AgentAccount: data.DownlineAgent,
        Amount: data.Amount,
        Remark: data.Remark,
      });
    }
  }, [isOpen]);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (formData: any) => {
    setLoading(true);
    const positive = getFormValues().TransferType === TRANSFER_TYPE.deductBalance ? -1 : 1
    const send = {
      OnlineAgent: accountType === AGENT_RELATION.upperAgentName ? formData.AgentAccount : null,
      DownlineAgent: accountType === AGENT_RELATION.subAgent ? formData.AgentAccount : null,
      Amount: formData.Amount * positive,
      Remark: formData.Remark,
    };
    $post({
      url: 'api/transfer/agent',
      send,
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setLoading(false);
        close();
        clear();
        mutate();
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        } else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  return (
    <Modal
      title={i18n.t('confirmTransfer')}
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button></Col>
          <Col><Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false}
            onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <div><span className="require">*</span>{i18n.t('agentAccount')}</div>
              <div>
                <Form.Item name="AgentAccount">
                  <Input placeholder={`${i18n.t('inputData')}`} disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div><span className="require">*</span>{i18n.t('amount')}</div>
              <div>
                <Form.Item name="Amount">
                  <Input placeholder={`${i18n.t('inputData')}`} disabled />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div><span className="require">*</span>{i18n.t('explanation')}</div>
              <div>
                <Form.Item name="Remark">
                  <Input.TextArea
                    disabled
                    size="middle"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    placeholder={`${i18n.t('inputData')}`}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

/* 存款 */
export const Deposit = ({ isCashVersion, size }: {
  isCashVersion: boolean;
  size: number;
}) => {
  enum GATEWAY_CODE {
    銀行卡 = 'DEPOSIT_GATE_BANK_OFFLINE',
    虛擬貨幣 = 'DEPOSIT_GATE_CRYPTO_OFFLINE',
  }
  const { permissions: $p } = useAccount();
  const [currentManufacturer, setCurrentManufacturer] = useState({});
  const [offlineDeposit, setOfflineDeposit] = useState(false);
  const [paymaya, setPaymaya] = useState(false);
  const [onlineBanking, setOnlineBanking] = useState(false);

  const { data } = $get({ url: 'api/gateway/deposit/list' });

  const handleRecharge = (record: any) => {
    switch (record.Code) {
      case GATEWAY_CODE.銀行卡:
        setOfflineDeposit(true);
        break;
      case GATEWAY_CODE.虛擬貨幣:
        setOnlineBanking(true);
        break;
      // 三方
      default:
        setCurrentManufacturer(record);
        setPaymaya(true);
        break;
    };
  };

  return (
    <>
      {($p('0302') && isCashVersion) &&
        <Col span={$mobile ? 24 : size}>
          <Card className="card-shadow" bodyStyle={{ padding: '16px' }} style={{ height: '100%' }} title={i18n.t('depositing')} >
            <Table
              size="middle"
              loading={false}
              dataSource={data?.Data?.map((item: Gateway_data) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  dataIndex: 'Code',
                  title: i18n.t('rechargeType'),
                  render: (val: string) => i18n.t(val),
                },
                {
                  title: i18n.t('operation'),
                  width: 60,
                  render: (val: string, record: Gateway_data) => (
                    <Button type="link" onClick={() => handleRecharge(record)}>{i18n.t('depositing')}</Button>
                  )
                },
              ]}
              pagination={false}
            />
          </Card>
        </Col>}

      {/* 存款銀行卡 */}
      <PopupOfflineDepositModal isOpen={offlineDeposit} close={() => setOfflineDeposit(false)} />
      {/* 存款虛擬貨幣 */}
      <PopupOnlineBankingModal isOpen={onlineBanking} close={() => setOnlineBanking(false)} />
      {/* 存款三方 */}
      <PopupPaymayaModal isOpen={paymaya} close={() => setPaymaya(false)} currentManufacturer={currentManufacturer} />
    </>
  );
};

/* 轉帳 */
export const Transfer = ({ size, isCashVersion, account, verifyAmount, mutate }: {
  isCashVersion: boolean;
  size: number;
  account: AccountInfo;
  verifyAmount: (rule: any, value: any, callback: any) => void;
  mutate: () => void;
}) => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [amountValue, setAmountValue] = useState<number>(0);
  // NOTE: 轉帳成功要清空欄位
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [agentList, setAgentList] = useState<TableAttributes>({});
  // 上線帳號
  const { data: _agentUpagentInfo } = $get({ url: 'api/agent/upagent/info' });
  // 下線帳號
  const { data: _agentList } = $get({
    url: 'api/report/agent/list',
    params: {
      PageIndex: 1,
      PageSize: 999,
    }
  });

  const [accountType, setAccountType] = useState();
  const updateAccountType = (value: any) => {
    setAccountType(value);
    form.setFieldsValue({
      DownlineAgent: null,
      Amount: '',
      Remark: '',
    });
    switch (value) {
      case AGENT_RELATION.upperAgentName:
        if (account.Level === 0) {
          setAgentList({
            dataSource: [],
            total: 0
          });
        } else {
          setAgentList({
            dataSource: [{
              value: _agentUpagentInfo.Data.Account,
              label: _agentUpagentInfo.Data.Account,
            }],
            total: 1
          });
        }
        break;
      case AGENT_RELATION.subAgent:
        const data = _agentList.Data.map((item: any, i: number) => ({
          value: item.Account,
          label: item.Account,
        }))
        setAgentList({
          dataSource: data,
          total: _agentList.TotalRecord
        })
        break;

      default:
        break;
    };
  };

  return (
    <>
      {$p('0303') &&
        <Col span={$mobile ? 24 : size}>
          <Card className="card-shadow" bodyStyle={{ padding: '20px 20px' }} style={{ height: '100%' }} title={i18n.t('transfer')} >
            <Form layout="vertical" form={form} onFinish={() => setIsOpenTransferModal(true)}>
              <Row gutter={[12, 12]}>
                {/* 選代理 */}
                <Col span={24}>
                  <Row align="middle" gutter={[12, 12]}>
                    <Col span={12}>
                      <div>
                        <Form.Item name="agentAccount" label={i18n.t('agentAccount')} rules={[{ required: true }]}>
                          <Select
                            className="w-full"
                            placeholder={`${i18n.t('pleaseSelect')}`}
                            options={
                              enumToOptions(AGENT_RELATION)
                                .filter((r: any) => !(account.Level === 0 && r.value === AGENT_RELATION.upperAgentName))
                            }
                            onChange={updateAccountType}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="DownlineAgent" label={i18n.t('account')} rules={[{ required: true }]}>
                        <Select
                          disabled={!accountType}
                          className="w-full"
                          placeholder={`${i18n.t('pleaseSelect')}`}
                          options={agentList.dataSource}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {isCashVersion
                // 現金版
                ? <>
                <Col span={24}>
                  <Form.Item name="Amount" label={i18n.t('amount')} rules={[
                    {
                      validator: verifyAmount
                    },
                    ...verify({ point: 4, zero: false, max: 999999999.9999 })
                  ]}>
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                </>
                // 信用版
                : <>
                <Col span={12}>
                  <Form.Item name="TransferType" label={i18n.t('amount')} rules={verify({ point: 4 })}>
                    <Select
                      disabled={!accountType}
                      className="w-full"
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      options={
                        enumToOptions(TRANSFER_TYPE)
                          .filter((r: any) => !(accountType === AGENT_RELATION.upperAgentName && r.value === TRANSFER_TYPE.deductBalance))
                      }
                      onChange={(value) => {
                        setAmountValue(value)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div style={{ marginTop: '23px' }}>
                    <Form.Item name="Amount" rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                      <Input disabled={!amountValue} placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </div>
                </Col>
                </>
                }
                {/* 備註 */}
                <Col span={24}>
                  <Form.Item name="Remark" label={i18n.t('explanation')} rules={[{ required: true }]}>
                    <Input.TextArea
                      size="middle"
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      placeholder={`${i18n.t('inputData')}`}
                      maxLength={100}
                      showCount
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="center">
                  <Button onClick={form.submit} style={{ marginTop: '5px' }}>{i18n.t('send')}</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>}
      {/* 確認轉帳 */}
      <PopupTransferModal
        isOpen={isOpenTransferModal}
        close={() => setIsOpenTransferModal(false)}
        clear={() => form.resetFields()}
        getFormValues={form.getFieldsValue}
        accountType={accountType}
        mutate={mutate}
      />
    </>
  )
};

/* 取款 */
export const Withdraw = ({ isCashVersion, size, verifyAmount }: {
  isCashVersion: boolean;
  size: number;
  verifyAmount: (rule: any, value: any, callback: any) => void;
}) => {
  const { permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const [form] = useForm();
  const [amount, setAmount] = useState(0);
  const [isOpenAddBankcardModal, setIsOpenAddBankcardModal] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [withdrawmethodAgentList, setWithdrawmethodAgentList] = useState([]);
  // 取得提款卡列表
  const { data: _withdrawmethodAgentList, mutate: mutateCardList } = $get({
    url: 'api/withdrawmethod/agent/list',
    params: {
      PageIndex: 1,
      PageSize: 9999,
    }
  })
  useEffect(() => {
    if (_withdrawmethodAgentList?.Data) {

      const data: any = _withdrawmethodAgentList.Data
        .filter((item: any) => (
          (item.GatewayCode === GATEWAY_CODE_WITHDRAW.銀行卡 && item.Status === ENABLE.enabled) ||
          (item.GatewayCode === GATEWAY_CODE_WITHDRAW.虛擬貨幣 && item.MerchantStatus === ENABLE.enabled)
        ))

      setWithdrawmethodAgentList(data);
    } else {
      setWithdrawmethodAgentList([]);
    }
  }, [_withdrawmethodAgentList]);

  // 提款卡資料
  const [bankcard, setBankcard] = useState<any>({});
  const updateBankcard = (value: string) => {
    const bankData = withdrawmethodAgentList.find((item: any) => item.Id === value);
    setBankcard(bankData);
    form.setFieldValue('Amount', '');
  }

  return (
    <>
      {($p('0304') && isCashVersion) &&
        <Col span={$mobile ? 24 : size}>
          <Card className="card-shadow" bodyStyle={{ padding: '20px 20px' }} style={{ height: '100%' }} title={
            <Row align="middle" justify="space-between">
              <Col className="size-16" style={{ fontWeight: 600 }}>{i18n.t('withdrawal')}</Col>
              <Col>
                <Button type="primary" onClick={() => setIsOpenAddBankcardModal(true)}>{i18n.t('addPaymentMethod')}</Button>
              </Col>
            </Row>
          }>
            {(withdrawmethodAgentList?.length || 0) > 0 ?
              <Form layout="vertical" form={form} onFinish={() => setIsOpenWithdrawModal(true)}>
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <Form.Item name="WithdrawMethodId" label={i18n.t('paymentMethod')} rules={[{ required: true }]}>
                      <Select
                        className="w-full"
                        placeholder={`${i18n.t('pleaseSelect')}`}
                        options={withdrawmethodAgentList.map((item: any) => (
                          { value: item.Id, label: item.BankName || item.Crypto }
                        ))}
                        onChange={updateBankcard}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Descriptions size="small" layout={$mobile ? 'vertical' : 'horizontal'} column={1} bordered
                      labelStyle={{ fontSize: 12, fontWeight: 600, minWidth: 170, width: 170 }}
                    >
                      {bankcard.BankName ?
                        <Descriptions.Item label={i18n.t('bankName')}>
                          {bankcard.BankName}
                        </Descriptions.Item> : null
                      }
                      {bankcard.BankAccount ?
                        <Descriptions.Item label={i18n.t('bankAccount')}>
                          {bankcard.BankAccount}
                        </Descriptions.Item> : null
                      }
                      {bankcard.Name ?
                        <Descriptions.Item label={i18n.t('bankAccountName')}>
                          {bankcard.Name}
                        </Descriptions.Item> : null
                      }
                      {
                        $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
                          : <>
                            {bankcard.Branch ?
                              <Descriptions.Item label={i18n.t('province')}>
                                {bankcard.Branch}
                              </Descriptions.Item> : null
                            }
                            {bankcard.City ?
                              <Descriptions.Item label={i18n.t('city')}>
                                {bankcard.City}
                              </Descriptions.Item> : null
                            }
                            {bankcard.PhonePrefix ?
                              <Descriptions.Item label={i18n.t('countryCode')}>
                                {bankcard.PhonePrefix}
                              </Descriptions.Item> : null
                            }
                            {bankcard.PhoneNumber ?
                              <Descriptions.Item label={i18n.t('phoneNumber')}>
                                {bankcard.PhoneNumber}
                              </Descriptions.Item> : null
                            }
                          </>
                      }
                      {bankcard.Crypto ?
                        <Descriptions.Item label={i18n.t('currency')}>
                          {bankcard.Crypto}
                        </Descriptions.Item> : null
                      }
                      {bankcard.CryptoType ?
                        <Descriptions.Item label={i18n.t('networkType')}>
                          {bankcard.CryptoType}
                        </Descriptions.Item> : null
                      }
                      {bankcard.CryptoAddress ?
                        <Descriptions.Item label={i18n.t('userAddress')}>
                          {bankcard.CryptoAddress}
                        </Descriptions.Item> : null
                      }
                      {bankcard.Photo && JSON.parse(bankcard.Photo).length > 0 ?
                        <Descriptions.Item label={i18n.t('qrCode')}>
                          <Image src={JSON.parse(bankcard.Photo)[0]} width={80} height={80} />
                        </Descriptions.Item> : null
                      }
                    </Descriptions>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="Amount" label={i18n.t('amount')} rules={[
                      {
                        validator: verifyAmount
                      },
                      ...verify({ point: 4, zero: false })
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setAmount(Number(e.target.value))} />
                    </Form.Item>
                    {/* <div className="color-05 size-10">
                          {`${i18n.t('single')}: ${setting?.Data.LimitMin} - ${setting?.Data.LimitMax}`}
                        </div> */}
                  </Col>
                  {
                    bankcard.Crypto ?
                      <div className="color-05 size-12">
                        {i18n.t('exchangeRate')}: <span className="color-07">≈ {toFormatNumber(Number(amount) / bankcard.ExchangeRate, 2, 'floor')} {bankcard.Crypto}</span>
                      </div> : null
                  }
                  <Col span={24} className="center">
                    <Button type="primary" style={{ marginTop: '5px' }} onClick={form.submit}>{i18n.t('submitWithdrawal')}</Button>
                  </Col>
                </Row>
              </Form> :
              <Empty description={i18n.t('noBankCardInformationAvailable')} />
            }
          </Card>
        </Col>}
      {/* 新增收款方式 */}
      <PopupWithdrawMethodModal 
        isOpen={isOpenAddBankcardModal} 
        close={() => setIsOpenAddBankcardModal(false)} 
        mutate={mutateCardList} 
      />
      {/* 提款申請 */}
      <PopupWithdrawModal
        isOpen={isOpenWithdrawModal}
        close={() => setIsOpenWithdrawModal(false)}
        form={form}
        setBankcard={setBankcard}
      />
    </>
  )
};

/* 交易紀錄 */
export const TransactionHistory = ({ account }: {
  account: AccountInfo;
}) => {
  const [form] = useForm();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    PageIndex: 1,
    PageSize: 10,
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1]),
  });
  const [cashlogAgentList, setCashlogAgentList] = useState<TableAttributes>({});
  const { data: _cashlogAgentList, mutate, isValidating } = $get({
    url: 'api/cashlog/agent/list',
    params: searchParams
  })
  useEffect(() => {
    if (_cashlogAgentList && _cashlogAgentList.Data) {
      setCashlogAgentList({
        dataSource: _cashlogAgentList.Data,
        total: _cashlogAgentList.TotalRecord
      });
    } else {
      setCashlogAgentList({
        dataSource: [],
        total: 0
      });
    }
  }, [_cashlogAgentList]);
  const search = (formData: any) => {
    setSearchParams({
      KeyId: formData.KeyId === AGENT_LOG.agentTransferOut ? AGENT_LOG.agentTransferIng : formData.KeyId,
      PageIndex: 1,
      PageSize: searchParams.PageSize,
      CreateStartDate: timeL2S(date[0]),
      CreateEndDate: timeL2S(date[1]),
    })
    mutate();
  };
  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  };

  return (
    <Col span={24}>
      <Form className='form-search' style={{ marginTop: '18px' }} form={form} onFinish={search}>
        <Row gutter={[12, 12]} style={{ maxWidth: '1200px' }}>
          <Col span={$mobile ? 24 : 4}>
            <Form.Item name="KeyId">
              <Select
                placeholder={i18n.t('selectCategory')}
                options={[
                  { value: '', label: `${i18n.t('ALL')}` },
                  ...enumToOptions(AGENT_LOG).filter(item => !(item.value === 1013))
                ]}
              />
            </Form.Item>
          </Col>
          <DatePickerCol span={$mobile ? 24 : ''}
            initDate={initDate} date={date} setDate={setDate} textAlign={'right'} />
          <Col span={$mobile ? 24 : ''} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={form.submit} style={{ marginRight: '8px' }}>{i18n.t('search')}</Button>
            <Button onClick={onClear}>{i18n.t('clear')}</Button>
          </Col>
        </Row>
      </Form>
      <Table
        className='mt-2'
        size="middle"
        scroll={{ x: 400 }}
        loading={isValidating}
        dataSource={cashlogAgentList.dataSource?.map((item: any) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'KeyId',
            width: 150,
            fixed: 'left',
            title: i18n.t('type'),
            render: (val: any, { Amount }: any) => (
              val === String(AGENT_LOG.agentTransferIng) && Amount < 0 ? i18n.t(AGENT_LOG[AGENT_LOG.agentTransferOut]) : i18n.t(AGENT_LOG[val as any])
            )
          },
          {
            dataIndex: 'UpdateDate',
            width: 180,
            title: i18n.t('transactionTime'),
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'TargetAccount',
            width: 180,
            title: i18n.t('agentMemberAccount'),
            render: (val, { OperatorAccount }) => val === account.Account ? OperatorAccount : val
          },
          {
            dataIndex: 'BeforeAmount',
            width: 180,
            title: i18n.t('previousBalance'),
            align: 'right',
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Amount',
            width: 180,
            title: i18n.t('transactionAmount'),
            align: 'right',
            render: (val) => $f(val)
          },
          {
            dataIndex: 'AfterAmount',
            width: 180,
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            render: (val) => $f(val)
          },
          {
            dataIndex: 'Remark',
            title: i18n.t('remark'),
            width: 250,
            render: (val: any) => val === "null" || val === "" ? '-' : val
          },
        ]}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
          total: cashlogAgentList.total,
          pageSize: searchParams.PageSize
        }}
        onChange={(p) => handleTableChange(searchParams, setSearchParams, p)}
      />
    </Col>
  )
}
