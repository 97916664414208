import { AGENT_STATE, MEMBER_FREEZE, MEMBER_STATE } from "enum/state";
import i18n from "i18n";
import { toFormatNumber as $f, timeS2L } from 'utils/common';

export const ExcelColumns = {
  MemberList: [
    {
      title: i18n.t('memberAccount'),
      dataIndex: "Account",
      excelRender: (text: string, record: any, index: number) => text
    },
    {
      title: i18n.t('promotionCode'),
      dataIndex: "RefCode",
    },
    {
      title: i18n.t('recommendedMembers'),
      dataIndex: "UPMemberAccount",
    },
    {
      title: i18n.t('agent'),
      dataIndex: "AGAccount",
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (text: number) => i18n.t(MEMBER_FREEZE[text ? MEMBER_FREEZE.frozen : MEMBER_FREEZE.normal]),
    },
    {
      title: i18n.t('audit'),
      dataIndex: 'Status',
      excelRender: (text: number) => i18n.t(MEMBER_STATE[text]),
    },
    {
      title: i18n.t('memberLevel'),
      dataIndex: 'MemberLevelName',
    },
    {
      title: i18n.t('creationTime'),
      dataIndex: "CreateDate",
      render: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('IP'),
      dataIndex: "RegisterIP",
    },
    {
      title: i18n.t('lastLoginTime'),
      dataIndex: "LastLoginDate",
      render: (val: any) => timeS2L(val) || '-'
    },
    {
      title: i18n.t('IP'),
      dataIndex: "IP",
    },
    {
      title: i18n.t('lastDepositTime'),
      dataIndex: "LastDepositTime",
      render: (val: any) => timeS2L(val) || '-'
    },
    {
      title: i18n.t('remark'),
      dataIndex: "RemarkFromAccount",
    },
  ],
  // 代理列表
  // NOTE: 要與header合併
  AgentList: [
    {
      title: i18n.t('account'),
      dataIndex: 'Account',
    },
    {
      title: i18n.t('agentCount'),
      dataIndex: 'DownlineAgentCount',
      excelRender: (val: any) => $f(val, 0)
    },
    {
      title: i18n.t('memberCount'),
      dataIndex: 'DownlineMemberCount',
      excelRender: (val: any) => $f(val, 0)
    },
    {
      title: i18n.t('depositing'),
      dataIndex: 'DepositAmount',
      excelRender: (val: any) => $f(val)
    },
    {
      title: i18n.t('withdrawal'),
      dataIndex: 'WithdrawAmount',
      excelRender: (val: any) => $f(val)
    },
    {
      title: i18n.t('betAmountValid'),
      dataIndex: 'ValidBet',
      excelRender: (val: any) => $f(val)
    },
    {
      title: i18n.t('winLose'),
      dataIndex: 'WinLossAmount',
      excelRender: (val: any) => $f(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      excelRender: (val: number) => AGENT_STATE[val]
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      render: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('lastOperationTime'),
      dataIndex: 'LastLoginDate',
      render: (val: any) => timeS2L(val) || '-'
    },
    {
      title: i18n.t('remark'),
      dataIndex: 'Remark',
    }
  ],
}