import { LocaleKey } from "../constants/locale.constant";
import languages from "../locales/languages.json";

export const getLocaleLanguage = (locale: LocaleKey) => {
  const language: any = {};

  languages.map((item: any) => {
    language[item['code']] = item[locale];
  });

  return language;
};
