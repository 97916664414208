// 語系代碼 格式：{iso-639-1 language code 小寫}-{iso-3166 country codes 大寫}
export enum Locale {
  'zh-TW' = 'zh-TW',
  'zh-CN' = 'zh-CN',
  'en-US' = 'en-US',
  'vi-VN' = 'vi-VN',
  'th-TH' = 'th-TH',
}

// 語系欄位名稱 格式：{iso-639-1 language code 小寫}{iso-3166 country codes 大寫}
export enum LocaleKey {
  'zh-TW' = 'zhTW',
  'zh-CN' = 'zhCN',
  'en-US' = 'enUS',
  'vi-VN' = 'viVN',
  'th-TH' = 'thTH',
}
