import vi_VN from 'antd/locale/vi_VN';

const customViVN = {
  ...vi_VN,
  Pagination: {
    ...vi_VN.Pagination,
    items_per_page: '/page',
  },
};

export default customViVN;