import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { useRouteError } from "react-router-dom";

import PageWallet from "../pages/admin/wallet";

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const walletModule = [
  {
    path: "/", ErrorBoundary,
    children: [
      { path: '/wallet', Component: PageWallet },
    ],
  },
]