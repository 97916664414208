import { createSlice } from "@reduxjs/toolkit";

interface SearchType {
  member: null | any;
  agent: null | any;
}

const initialState: SearchType = {
  member: null,
  agent: null,
}

const searchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {
    setSearchHistory: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    clearSearchHistory: (state) => {
      return initialState
    }
  }
})

export const { setSearchHistory, clearSearchHistory } = searchSlice.actions;
export default searchSlice.reducer;