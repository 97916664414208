import { DatabaseFilled, InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CopyButton, DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f, timeL2S, timeS2L } from "utils/common";
import { handleTableChange } from 'utils/table';

// 投注報表
// AGBO 相同頁面

enum REPORT_STATE {
  notSettle = 0,
  settled = 1,
  unsettledCancellation = -1,
  settledCancellation = 2,
}

const PageMain: React.FC = () => {
  const { init, info, permissions: $p } = useAccount();
  const { data: site } = useSite();

  const { account } = useParams();

  const [form] = Form.useForm();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);

  const [page, setPage] = useState<number[]>([1, 10]);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    MemberAccount: account || '',
    EndTimeStart: timeL2S(date[0]),
    EndTimeEnd: timeL2S(date[1]),
  });
  const { data, mutate, isValidating } = $get({
    url: 'api/betticket/list',
    params: {
      ...searchParams,
      PageSize: page[1],
      PageIndex: page[0],
    }
  })
  const search = (formData: any) => {
    setPage([1, page[1]]);

    const timeMode = form.getFieldValue('TimeMode') || '0';
    const type = timeMode === '0' ? 'End' : 'Bet';
    setSearchParams({
      RoundId: formData.RoundId,
      MemberAccount: formData.MemberAccount,
      ProviderCode: formData.ProviderCode,
      CategoryCode: formData.CategoryCode,
      Status: formData.Status,
      [`${type}TimeStart`]: timeL2S(date[0]),
      [`${type}TimeEnd`]: timeL2S(date[1]),
      IsPromo: formData.providerPromotions
    })
    mutate()
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setInitDate(!initDate);
  }

  const [columns, setColumns] = useState<any>([]);
  // 聖經欄位顯示設定
  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked

        return true
      }
    })
    setColumns([...columns]);
  };

  useEffect(() => {
    if (init) {
      setColumns([
        {
          dataIndex: 'RoundId',
          title: i18n.t('roundNumber'),
          fixed: 'left',
          width: 200,
          show: true
        },
        {
          dataIndex: 'MemberAccount',
          title: i18n.t('memberAccount'),
          width: 150,
          show: true
        },
        {
          dataIndex: 'ProviderName',
          title: i18n.t('gameProviderCategory'),
          width: 150,
          show: true,
          render: (val: any, { ProviderCode, CategoryCode }: any) => (
            <span>{common.specialProviderName(val, site.SiteName, ProviderCode)}／{i18n.t(CategoryCode)}</span>
          )
        },
        {
          dataIndex: 'LangName',
          title: i18n.t('gameName'),
          width: 150,
          show: true,
          render: (val: any, { GameName }: any) => common.gameNameTransfer(val, GameName)
        },
        {
          dataIndex: 'BetAmount',
          title: i18n.t('betAmount'),
          align: 'right',
          width: 200,
          show: true,
          render: (val: any) => $f(val)
        },
        {
          dataIndex: 'ValidBetAmount',
          title: i18n.t('betAmountValid'),
          align: 'right',
          width: 200,
          show: true,
          render: (val: any) => $f(val)
        },
        {
          dataIndex: 'SettleAmount',
          width: 200,
          align: 'right',
          title: () => (
            <>
              <Tooltip placement="top" className="size-12" title={i18n.t('betAmountAndMemberWinLoss')}>
                <InfoCircleFilled />
              </Tooltip>
              <span style={{ marginLeft: 5 }}>{i18n.t('payout')}</span>
            </>
          ),
          show: true,
          render: (val: any, { Status }: any) =>
            (Status === REPORT_STATE.settled) ? $f(val) : '-'
        },
        {
          dataIndex: 'WinLossAmount',
          title: i18n.t('memberWinLoss'),
          width: 200,
          align: 'right',
          show: true,
          render: (val: any, { Status }: any) =>
            (Status === REPORT_STATE.settled) ?
              <span className={common.numColor01(val)}>{$f(val)}</span> : '-'
        },
        {
          dataIndex: 'Status',
          title: i18n.t('status'),
          width: 100,
          show: true,
          render: (val: any) => i18n.t(REPORT_STATE[val])
        },
        {
          dataIndex: 'BetTime',
          title: i18n.t('betTime'),
          width: 200,
          show: true,
          render: (val: any) => timeS2L(val)
        },
        {
          dataIndex: 'EndTime',
          title: i18n.t('settlementTime'),
          width: 200,
          show: true,
          render: (val: any) => val
            ?
            <Row justify="start" align="middle" className={'size-12'}>
              <span>{timeS2L(val)}</span>
              <CopyButton text={timeS2L(val)} />
            </Row>
            : '-'
        },
        {
          dataIndex: 'Updatedate',
          title: i18n.t('cancellationTime'),
          width: 200,
          show: true,
          render: (val: any) => val || '-'
        },
      ])
    }
  }, [init]);

  // 列表
  // NOTE: 封裝欄位顯示功能
  const [showColumn, setShowColumn] = useState<string[]>([]);
  useEffect(() => {
    setShowColumn(columns.map((item: any) => item.dataIndex));
  }, []);
  const onChangeColumn = (name: string, checked: boolean) => {
    if (checked) {
      setShowColumn([
        ...showColumn,
        name,
      ])
    } else {
      setShowColumn(showColumn.filter(item => item !== name));
    }
  };

  // 遊戲商
  const { data: provider } = $get({
    url: 'api/provider/code/list'
  })
  // 遊戲大類
  const { data: category } = $get({
    url: 'api/category/list',
    params: { Status: 1 }
  })
  const searchSpan = common.$mobile ? 12 : 4

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <LayoutNav />
      <Content>
        <Form className='form-search' form={form} onFinish={search} initialValues={{
          TimeMode: '0',
          MemberAccount: account || '',
        }}>
          <Row gutter={[12, 12]} style={{ maxWidth: '1200px' }}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={searchSpan}>
                  <Form.Item name="RoundId">
                    <Input placeholder={`${i18n.t('roundNumber')}`} />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="MemberAccount">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                {/* 遊戲商 */}
                <Col span={searchSpan}>
                  <Form.Item name="ProviderCode">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      filterOption={filterOption}
                      placeholder={`${i18n.t('gameProvider')}`}
                      options={
                        provider && provider.Data && [
                          { value: '', label: i18n.t('ALL') },
                          ...provider.Data.map((item: any) => ({
                            value: item.ProviderCode,
                            label: common.specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode)
                          }))
                        ]
                      }
                    />
                  </Form.Item>
                </Col>
                {/* 遊戲大類 */}
                <Col span={searchSpan}>
                  <Form.Item name="CategoryCode">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('gameCategory')}`}
                      options={
                        category && category.Data &&
                        [
                          { value: '', label: i18n.t('ALL') },
                          ...category.Data.map((item: any) => ({
                            key: item.Id,
                            value: item.Code,
                            label: i18n.t(`${item.Code}`)
                          }))
                        ]
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className='w-12'>
                  <Form.Item name="providerPromotions">
                    <Select
                      placeholder={i18n.t('type')}
                      options={[
                        { value: null, label: i18n.t('ALL') },
                        { value: 0, label: i18n.t('bettingSlip') },
                        { value: 1, label: i18n.t('providerPromotions') },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={searchSpan}>
                  <Form.Item name="Status">
                    <Select
                      style={{ width: '100%' }}
                      placeholder={`${i18n.t('status')}`}
                      options={[
                        { value: "", label: i18n.t('ALL') },
                        ...common.enumToOptions(REPORT_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="TimeMode">
                    <Select
                      style={{ width: '100%' }}
                      options={[
                        { value: '0', label: i18n.t('settlementTime') },
                        { value: '1', label: i18n.t('betTime') },
                      ]}
                    />
                  </Form.Item>
                </Col>
                {/* FIXME: 補做區間只能7天 disabledDate */}
                <DatePickerCol span={common.$mobile ? 24 : 4} initDate={initDate} date={date} setDate={setDate}
                  ignoreDateType={[DATE_TYPE.thisMonth, DATE_TYPE.lastMonth]} textAlign={'right'} rangeLimit={DATE_RANGE_LIMIT.past7Days} />
              </Row>
            </Col>
            {/* 按鈕 */}
            <Col span={24}>
              <Row justify={common.$mobile ? 'end' : 'start'} gutter={[12, 12]}>
                <Col>
                  <Tooltip placement="top" className="size-12" title={i18n.t('singleQueryInterval7Days')}>
                    <InfoCircleFilled style={{ paddingTop: '10px', paddingLeft: '7px' }} />
                  </Tooltip>
                </Col>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row align="middle" className="mt-1" gutter={[12, 12]}>
          {/* 總覽 */}
          <Col span={24}>
            <Table
              size="middle"
              loading={isValidating}
              dataSource={
                data ? [{
                  TotalRecord: data?.TotalRecord,
                  TotalBetAmount: data?.TotalBetAmount,
                  TotalValidBetAmount: data?.TotalValidBetAmount,
                  TotalWinLossAmount: data?.TotalWinLossAmount,
                }] : []
              }
              columns={[
                {
                  dataIndex: 'TotalRecord',
                  title: i18n.t('betCount'),
                  align: 'center',
                  width: '25%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalBet'),
                  align: 'center',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalValidBetAmount',
                  title: i18n.t('betAmountValid'),
                  align: 'center',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalWinLossAmount',
                  title: i18n.t('memberWinLoss'),
                  align: 'center',
                  width: '25%',
                  render: (val) => $f(val)
                },
              ]}
              pagination={false}
            />
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Popover
                  placement="bottomRight"
                  trigger="click"
                  content={
                    <div className="w-full">
                      {columns
                        .filter((item: any) => !item.isOpenMore)
                        .map((item: any) =>
                          <div key={item.key}>
                            <Checkbox
                              checked={item.show}
                              onChange={e => {
                                updateColumnShow(item.dataIndex, e.target.checked)
                              }}
                            >
                              {typeof item.title === 'function' ? (
                                React.Children.map(item.title().props.children[1], (child) =>
                                  typeof child === 'object' && child.type === 'span' ? (
                                    child.props.children
                                  ) : (
                                    child
                                  )
                                )
                              ) : (
                                item.title
                              )}
                            </Checkbox>
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button><DatabaseFilled /></Button>
                </Popover>
              </Col>
            </Row>
          </Col>
          {/* 列表 */}
          <Col span={24}>
            <Table
              size="middle"
              scroll={{ x: 400 }}
              loading={isValidating}
              dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
              columns={
                columns
                  .filter((item: any) => item.show)
              }
              pagination={{
                showSizeChanger: true,
                showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                total: data?.TotalRecord,
              }}
              onChange={(p: any) => handleTableChange(searchParams, setSearchParams, p)}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageMain;
