import { Card, Col, Image, Row, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import coinIcon from 'assets/image/coin.svg';
import { LayoutNav } from 'components/layout.component';
import {
  Deposit,
  TransactionHistory,
  Transfer,
  Withdraw
} from 'components/wallet.component';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { toFormatNumber as $f, $mobile } from "utils/common";

// 錢包

const PageMain: React.FC = () => {
  const { init, info, mutate: mutateAccount, permissions: $p } = useAccount();
  const { isCashVersion: $sc, isValidating: $scValidating } = useSite();
  const [cardSize, setCardSize] = useState<number>(8);

  // 動態計算col寬度
  useEffect(() => {
    let col = 0;

    if ($p('0302')) col++
    if ($p('0303')) col++
    if ($p('0304')) col++

    setCardSize($sc ? (24 / col) : 24);

  }, [$sc, init])

  // 轉帳金額不能超過錢包餘額規則
  const verifyAmount = (rule: any, value: any, callback: any) => {
    const balance = parseFloat(info.Balance as any);
    if (parseFloat(value) > balance) {
      callback(i18n.t("insufficientBalance"));
    } else {
      callback();
    }
  };

  return (
    <>
      <LayoutNav />
      <Content>
        <Spin spinning={$scValidating}>
          <Row gutter={[18, 18]} className='mt-1'>

            {/* 錢包餘額 */}
            <Col span={24}>
              <Card className="card-shadow">
                <Row gutter={[12, 12]} align={'middle'}>
                  <Col style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Image src={coinIcon} width={40} height={40} preview={false} />
                    <span className="size-22 color-04 font-w-md mr-3">
                      {i18n.t('walletBalance')}
                    </span>
                  </Col>
                  <Col span={$mobile ? 24 : ''}>
                    <span className="size-22 color-04 font-w-md" style={{ marginLeft: '8px' }}>
                      {$f(info.Balance)}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* 存款 */}
            <Deposit isCashVersion={$sc} size={cardSize} />
            {/* 轉帳 */}
            <Transfer isCashVersion={$sc} size={cardSize} account={info} verifyAmount={verifyAmount} mutate={mutateAccount} />
            {/* 取款 */}
            <Withdraw isCashVersion={$sc} size={cardSize} verifyAmount={verifyAmount} />
            {/* 交易紀錄 */}
            <TransactionHistory account={info} />

          </Row>
        </Spin>
      </Content >
    </>
  );
};

export default PageMain;
