import { combineReducers, configureStore } from "@reduxjs/toolkit"

//slice
import searchSlice from "slice/searchSlice"

const rootReducers = combineReducers({
  Search: searchSlice,
})

const store = configureStore({
  reducer: rootReducers
})

export type RootType = ReturnType<typeof rootReducers>
export default store