//icon
import { Image, Tag } from 'antd';
import offline from 'assets/image/offline.svg';
import online from 'assets/image/online.svg';
import { MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import i18n from 'i18n';

const useIcon = () => {

  const memberStateIcon = (state: number, width = 'w-full') => {
    switch (state) {
      default:
      case MEMBER_STATE.waitApproval:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#DCDEE2', color: '#535A6C' }}
          >
            {i18n.t('waitApproval')}
          </Tag>
        )
      case MEMBER_STATE.approvaled:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('approvaled')}
          </Tag>
        )
      case MEMBER_FREEZE.normal:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('normal')}
          </Tag>
        )
      case MEMBER_FREEZE.frozen:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#E7716D', color: '#fff' }}>
            {i18n.t('frozen')}
          </Tag>
        )
    }
  }

  return {
    MemberStateTag: (state: number, width?: string) => memberStateIcon(state, width),
    StatusTag: (isOnline: boolean) => <Image style={{ paddingRight: 5 }} src={isOnline ? online : offline} preview={false} />,
  };
};

export default useIcon;
