import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { useRouteError } from "react-router-dom";

import PageMemberData from "../pages/admin/data";
import PageBetReport from "../pages/admin/data/bet-report";
import PageCashbackReport from "../pages/admin/data/cashback-report";
import PageCostReport from "../pages/admin/data/cost-report";
import PageDailyReport from "../pages/admin/data/daily-report";

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const dataModule = [
  {
    path: "/", ErrorBoundary,
    children: [
      { path: '/data', Component: PageMemberData },
      { path: '/data/daily-report', Component: PageDailyReport },
      { path: '/data/cost-report', Component: PageCostReport },
      { path: '/data/cashback-report', Component: PageCashbackReport },
      { path: '/data/bet-report/:account?', Component: PageBetReport },
    ],
  },
]