import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { useRouteError } from "react-router-dom";

import PageMemberList from "pages/admin/team";
import PageAgentList from "pages/admin/team/agent";
import PageAgentEdit from "pages/admin/team/agent-edit";
import PageMemberRebate from "pages/admin/team/member-rebate";
import PageMemberProvider from "pages/admin/team/member-provider";

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const teamModule = [
  {
    path: "/", ErrorBoundary,
    children: [
      { path: '/team', Component: PageMemberList },
      { path: '/team/member-rebate/:id/:account', Component: PageMemberRebate },
      { path: '/team/member-provider/:id/:account', Component: PageMemberProvider },
      { path: '/team/agent', Component: PageAgentList },
      { path: '/team/agent-edit/:id', Component: PageAgentEdit },
    ],
  },
]