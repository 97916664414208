export enum DATE_TYPE {
  today = 0,
  yesterday = 1,
  thisWeek = 2,
  lastWeek = 3,
  thisMonth = 4,
  lastMonth = 5,
}
export enum DATE_RANGE_LIMIT {
  all = 0,
  past7Days = 7,
  past31Days = 31,
  future = 99
}

export enum WEEK_DAY {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}