import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import { DailyList } from 'components/team.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 每日報表

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  // 需求: 週一開始
  const [date, setDate] = useState([
    dayjs().startOf('isoWeek').format('YYYY-MM-DD'),
    dayjs().endOf('isoWeek').format('YYYY-MM-DD'),
    DATE_TYPE.thisWeek
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [resetList, setResetList] = useState(false);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    startDate: date[0],
    endDate: date[1],
  });
  const { data: _reportRevenueList, isValidating, mutate: refreshReportRevenueList } = $get({
    url: 'api/report/revenue/list',
    params: searchParams
  })
  const { data: _reportRevenueshareList, isValidating: isValidatingShare, mutate: refreshReportRevenueshareList } = $get({
    url: 'api/report/revenueshare/list',
    params: searchParams
  })
  const refresh = () => {
    refreshReportRevenueList();
    refreshReportRevenueshareList();
  }
  const search = (formData: any) => {
    setSearchParams({
      startDate: date[0],
      endDate: date[1]
    })
    setResetList(!resetList)
    refresh();
  }
  const onClear = () => {
    form.resetFields();
    setReset(!reset);
  }

  // 前端算總計
  const [total, setTotal] = useState({
    key: 1,
    RevenueShare: 0,
    AgentCost: 0,
    Commission: 0,
    AgentProfit: 0
  });
  useEffect(() => {
    if (_reportRevenueshareList && _reportRevenueshareList) {
      const RevenueShare = _reportRevenueshareList?.Data?.map((item: any) => item.RevenueShare) || []
      const AgentCost = _reportRevenueshareList?.Data?.map((item: any) => item.AgentCost) || []
      const Commission = _reportRevenueList?.Data?.map((item: any) => item.Commission) || []
      const AgentProfit = _reportRevenueshareList?.Data?.map((item: any) => item.AgentProfit) || []
      setTotal({
        key: 1,
        RevenueShare: RevenueShare[0] || 0,
        AgentCost: AgentCost[0] || 0,
        Commission: Commission[0] || 0,
        AgentProfit: AgentProfit[0] || 0
      })
    }
  }, [_reportRevenueList, _reportRevenueshareList]);

  return (
    <>
      <LayoutNav />
      <Content>
        <Form className='form-search' form={form} onFinish={search}>
          <Row gutter={[12, 12]} style={{ maxWidth: '800px' }}>
            <DatePickerCol span={common.$mobile ? 24 : 6} initDate={reset} date={date} setDate={setDate}
              format={'YYYY-MM-DD'} textAlign='right' defaultDateType={DATE_TYPE.thisWeek} />
            <Col span={24}>
              {/* 按鈕 */}
              <Row justify={common.$mobile ? 'end' : 'start'} gutter={[12, 12]}>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row align="middle" className="mt-1" gutter={[12, 12]}>
          {/* 總計 */}
          <Col span={24}>
            <Table
              size="middle"
              loading={isValidating}
              dataSource={[total]}
              columns={[
                {
                  dataIndex: 'RevenueShare',
                  title: i18n.t('myTotalCommissionRate'),
                  align: 'center',
                  width: '25%',
                  render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                },
                {
                  dataIndex: 'AgentCost',
                  title: i18n.t('myTotalCost'),
                  align: 'center',
                  width: '25%',
                  render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                },
                {
                  dataIndex: 'Commission',
                  title: i18n.t('myTotalRebate'),
                  align: 'center',
                  width: '25%',
                  render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                },
                {
                  dataIndex: 'AgentProfit',
                  title: i18n.t('myTotalProfit'),
                  align: 'center',
                  width: '25%',
                  render: (val) => <span className={common.numColor01(val)}>{$f(val)}</span>
                },
              ]}
              pagination={false}
            />
          </Col>
          <Col span={24} className="size-14 font-w-md">{i18n.t('rebateReport')}</Col>
          {/* 返水 */}
          <Col span={24}>
            <DailyList loading={isValidating} scroll={{ x: 400 }} reset={resetList}
              data={_reportRevenueList?.Data?.map((item: any) => ({ Account: item.AgentAccount, ...item }))}
              columns={[
                {
                  dataIndex: 'Account',
                  title: i18n.t('account'),
                  fixed: 'left',
                  width: 150,
                  render: (val: any, { AgentLevel }: any) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return val ? `${word}／${val}` : ''
                  }
                },
                {
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  width: 100,
                  render: (val: any) => val === 'ALL' ? i18n.t('ALL') : i18n.t(val)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('accumulatedValidBets'),
                  width: 300,
                  align: 'right',
                  render: (val: any) => $f(val)
                },
                {
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('winLose'),
                  width: 200,
                  align: 'right',
                  render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
                  // render: (val, { winAndLoss }) => <div className={common.numColor03(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'DownlineCommission',
                  title: i18n.t('subordinateCashback'),
                  width: 200,
                  align: 'right',
                  render: (val: any) => (
                    $f(val)
                    // <>
                    //   <span style={{ marginRight: 5 }}>{$f(agentPayouts.amount)}</span>
                    //   {agentPayouts.percentage && <Tag style={{ background: '#757575', color: '#fff' }}>{agentPayouts.percentage}%</Tag>}
                    // </>
                  )
                },
                {
                  dataIndex: 'Commission',
                  title: i18n.t('myRebate'),
                  width: 200,
                  align: 'right',
                  render: (val: any) => (
                    <div className={common.numColor05(val)}>{common.toFormatNumber(val)}</div>
                    // <>
                    //   <Tag style={{ background: '#A7372D', color: '#fff' }}>{$f(payouts.amount)}</Tag>
                    //   {payouts.percentage && <Tag style={{ background: '#757575', color: '#fff' }}>{payouts.percentage}%</Tag>}
                    // </>
                  )
                },
              ]}
            />
          </Col>
          <Col span={24} className="size-14 font-w-md">{i18n.t('revenueReport')}</Col>
          {/* 占成 */}
          <Col span={24}>
            <DailyList loading={isValidatingShare} scroll={{ x: 400 }} reset={resetList}
              data={_reportRevenueshareList?.Data?.map((item: any) => ({ Account: item.AgentAccount, ...item }))}
              columns={[
                {
                  dataIndex: 'Account',
                  title: i18n.t('account'),
                  fixed: 'left',
                  width: 150,
                  render: (val: any, { AgentLevel }: any) => {
                    const word = common.agentLevelWord(AgentLevel);
                    return val ? `${word}／${val}` : ''
                  }
                },
                {
                  dataIndex: 'CategoryName',
                  title: i18n.t('gameCategory'),
                  width: 100,
                  render: (val: any) => val === 'ALL' ? i18n.t('ALL') : i18n.t(val)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('betAmountValid'),
                  align: 'right',
                  width: 100,
                  render: (val: any) => $f(val)
                },
                {
                  dataIndex: 'WinLoseAmount',
                  title: i18n.t('totalMemberWinLoss'),
                  align: 'right',
                  width: 100,
                  render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
                },
                {
                  dataIndex: 'TotalCost',
                  title: i18n.t('totalCost'),
                  align: 'right',
                  width: 100,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  dataIndex: 'DownlineRevenueShare',
                  title: i18n.t('subordinateCommissionRate'),
                  align: 'right',
                  width: 100,
                  render: (val: any) => $f(val)
                },
                {
                  dataIndex: 'DownlineAgentCost',
                  title: i18n.t('subordinateCost'),
                  align: 'right',
                  width: 100,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  dataIndex: 'DownlineAgentProfit',
                  title: i18n.t('subordinateProfit'),
                  align: 'right',
                  width: 100,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  dataIndex: 'RevenueShare',
                  title: i18n.t('myCommissionRate'),
                  align: 'right',
                  width: 100,
                  render: (val: any) => $f(val)
                },
                {
                  dataIndex: 'AgentCost',
                  title: i18n.t('myCost'),
                  align: 'right',
                  width: 100,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
                },
                {
                  dataIndex: 'AgentProfit',
                  align: 'right',
                  width: 100,
                  title:
                    <Row align="middle" justify="end" gutter={[5, 5]}>
                      <Col>
                        <Tooltip placement="top" title={
                          i18n.t('myProfitExplain')
                        }>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                      <Col className="size-12">{i18n.t('myProfit')}</Col>
                    </Row>,
                  render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ?
                    <div className={common.numColor05(val)}>{$f(val)}</div> : '-'
                },
              ]}
            />
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageMain;