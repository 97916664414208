import { ConfigProvider, message } from "antd";
import en_US from 'antd/locale/en_US';
import ja_JP from 'antd/locale/ja_JP';
import pt_BR from 'antd/locale/pt_BR';
import th_TH from 'antd/locale/th_TH';
import vi_VN from 'antd/locale/vi_VN';
import zh_CN from 'antd/locale/zh_CN';
import zh_TW from 'antd/locale/zh_TW';
import { MainLayout } from 'components/layout.component';
import { SocketProvider } from "contexts/socket.context";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { RouterProvider, createHashRouter } from "react-router-dom";
import {
  Slide,
  ToastContainer,
  ToastContainerProps,
  toast,
} from "react-toastify";
import store from "store/configureStore";
import "./assets/css/main.css";
import { dataModule } from "./modules/data.module";
import { teamModule } from "./modules/team.module";
import { walletModule } from "./modules/wallet.module";
import Page404 from "./pages/404";
import PageChat from "./pages/admin/chat";
import PageLogin from "./pages/admin/login";
import PageModifyPassword from "./pages/admin/modify-password";
import PageVerify from "./pages/admin/verify";
import PageError from "./pages/error";
import PageHome from './pages/home';
import { advancedModule } from "modules/advanced.module";
import customViVN from "locales/customViVN"
import { COOKIE } from 'constants/cookie';
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';

const toastConfig: ToastContainerProps = {
  transition: Slide,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1400,
  limit: 1,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  pauseOnHover: false,
};

message.config({
  top: 20,
  duration: 2,
  maxCount: 3,
})

const router = createHashRouter([
  {
    path: "/",
    children: [
      { path: '/', Component: PageHome },
      { path: '/login/:errorCode?', Component: PageLogin },
      { path: '/verify', Component: PageVerify },
      { path: '/password', Component: PageModifyPassword },
      { path: '/chat', Component: PageChat },
      ...dataModule,
      ...teamModule,
      ...walletModule,
      ...advancedModule,
      { path: '/error', Component: PageError },
      { path: "*", Component: Page404 },
    ],
  },
]);

ReactModal.setAppElement("#root");

const App: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [userLang, setUserLang] = useState(Cookies.get(COOKIE.LANG) || document.documentElement.lang);

  useEffect(() => {
    dayjs.extend(isoWeek);
  }, []);

  const Local = (lang: string) => {
    switch (lang) {
      case 'en-US':
        return en_US;
      case 'zh-TW':
        return zh_TW;
      case 'zh-CN':
        return zh_CN;
      case 'ja-JP':
        return ja_JP;
      case 'th-TH':
        return th_TH;
      case 'vi-VN':
        return customViVN;
      case 'pt-BR':
        return pt_BR;
    }
  }
  
  useEffect(() => {
    const lang = Cookies.get(COOKIE.LANG) || document.documentElement.lang;
    setUserLang(lang);
  }, [userLang, Cookies.get(COOKIE.LANG)]);

  return (
    <MainLayout>
      <Provider store={store}>
        <SocketProvider>
          <ConfigProvider locale={Local(userLang)}>
            {contextHolder}
            <ToastContainer {...toastConfig} />
            <RouterProvider router={router} />
          </ConfigProvider>
        </SocketProvider>
      </Provider>
    </MainLayout>
  );
};

export default App;