import { Col, Row, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabMember } from 'components/layout.component';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// 會員編輯 > 遊戲商資料

interface ProviderInfo {
  Id: number;
  MemberId: number;
  ProviderId: number;
  ProviderName: string;
  CategoryCode: string;
  Status: number;
}

const PageMain: React.FC = () => {
  const { id } = useParams();
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [modalType, setModalType] = useState<number | null>();
  const [data, setData] = useState<ProviderInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // const { data: MemberProviderList, mutate, isValidating } = MemberProvider({ MemberId: id });

  // useEffect(() => {
  //   if (MemberProviderList) {
  //     setData(MemberProviderList);
  //   }
  // }, [MemberProviderList])

  const handleAllStatus = (option: number) => {
    setModalType(option);
    setIsOpenStatusModal(true);
  }

  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
      setColumns([
        {
          dataIndex: 'ProviderCode',
          title: i18n.t('gameProvider'),
          width: '34%',
          render: (val: any, { CategoryCode }: any) => `${val}-${i18n.t(CategoryCode)}`
        },
        {
          dataIndex: 'GameAccount',
          title: i18n.t('gameProviderAccount'),
          width: '33%',
          render: (val: any) => val
        },
        {
          dataIndex: 'Status',
          title: i18n.t('status'),
          width: '33%',
          render: (val: any, record: any) => (
            <Row justify="start" align="middle" gutter={[12, 12]}>
              <Col>
                <Switch  checked={val} loading={isLoading} disabled={true} />
              </Col>
            </Row>
          )
        },
      ])
  }, []);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabMember activeKey="2" />
        <Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1">
            <Spin spinning={false}>
              <Row>
                <Col span={24}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.map(item => ({ ...item, key: item.ProviderName }))}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
      {/* <PopupStatusModal isOpen={isOpenStatusModal} close={() => setIsOpenStatusModal(false)}
        id={id || 0} type={modalType} mutate={mutate}
      /> */}
    </div >
  );
};

export default PageMain;