import { Card, Table } from "antd";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get } from "services";
import { toFormatNumber as $f } from 'utils/common';

// 首頁: 返水列表

export const ViewGameCategoryCashback = () => {
  const [agentOpp, setAgentOpp] = useState<any>([]);
  const { data: _agentOpp, isValidating } = $get({ url: 'api/agent/opp' });
  useEffect(() => {
    if (_agentOpp) {
      const games = {
        SLOT: [_agentOpp.Data.SlotCommissionRate, _agentOpp.Data.SlotRevenueShareRate],
        LIVE: [_agentOpp.Data.LiveCommissionRate, _agentOpp.Data.LiveRevenueShareRate],
        SPORTS: [_agentOpp.Data.SportsCommissionRate, _agentOpp.Data.SportsRevenueShareRate],
        ESPORTS: [_agentOpp.Data.ESportsCommissionRate, _agentOpp.Data.ESportsRevenueShareRate],
        POKER: [_agentOpp.Data.PokerCommissionRate, _agentOpp.Data.PokerRevenueShareRate],
        FISHING: [_agentOpp.Data.FishingCommissionRate, _agentOpp.Data.FishingRevenueShareRate],
        COCKFIGHT: [_agentOpp.Data.CockfightCommissionRate, _agentOpp.Data.CockfightRevenueShareRate],
        LOTTERY: [_agentOpp.Data.LotteryCommissionRate, _agentOpp.Data.LotteryRevenueShareRate],
        EGAMES: [_agentOpp.Data.EGamesCommissionRate, _agentOpp.Data.EGamesRevenueShareRate]
      } as any;

      const data = Object.keys(games)
        .map(key => ({
          key: games[key],
          gameCategory: key,
          revenueRate: games[key][0],
          rebateRate: games[key][1],
        }))
      setAgentOpp(data)
    }
  }, [_agentOpp]);

  return (
    <Card className="card-shadow" loading={isValidating}
      bodyStyle={{ padding: 0 }} title={
        <span className="color-04 size-14">{i18n.t('gameCategoryCashbackCommission')}</span>
      }>
      <Table
        bordered
        size="middle"
        columns={[
          {
            key: 1,
            dataIndex: 'gameCategory',
            title: i18n.t('gameCategory'),
            width: '50%',
            render: (val) => i18n.t(val)
          },
          {
            key: 2,
            dataIndex: 'revenueRate',
            title: `${i18n.t('revenueRate')} %`,
            width: '25%',
            render: (val) => $f(val * 100, 2)
          },
          {
            key: 3,
            dataIndex: 'rebateRate',
            title: `${i18n.t('rebateRate')} %`,
            width: '25%',
            render: (val) => $f(val * 100, 2)
          },
        ]}
        dataSource={agentOpp.map((item: any, i: number) => ({ ...item, key: `id-${i}` }))}
        pagination={false}
      />
    </Card>
  )
}

// 首頁: 活躍會員

export const ViewActiveMembersTop10 = ({ DateType }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, [DateType]);

  const [reportTop10Info, setReportTop10Info] = useState<TableAttributes>({});
  const { data: _reportTop10Info } = $get({
    url: 'api/report/top10/info',
    params: { DateType: DateType }
  })
  useEffect(() => {
    if (_reportTop10Info) {
      const data = _reportTop10Info.Data?.map((item: any, i: number) => ({
        key: item.MemberId,
        account: item.MemberAccount,
        rollover: item.TotalValidBetAmount,
        winLoss: item.TotalWinLossAmount
      }))
      setReportTop10Info({
        dataSource: data,
        total: _reportTop10Info.Data?.TotalRecord
      })
    } else {
      setReportTop10Info({
        dataSource: [],
        total: 0
      })
    }
  }, [_reportTop10Info]);

  return (
    <Card className="card-shadow" loading={isLoading}
      bodyStyle={{ padding: 0 }} title={
        <span className="color-04 size-14">{i18n.t('activeMembersTop10')}</span>
      }>
      <Table
        bordered
        loading={isLoading}
        size="middle"
        dataSource={reportTop10Info.dataSource}
        columns={[
          {
            title: i18n.t('account'),
            dataIndex: 'account',
            width: '50%'
          },
          {
            title: i18n.t('rollover'),
            dataIndex: 'rollover',
            width: '25%',
            render: (_, { rollover }) => $f(rollover)
          },
          {
            title: i18n.t('winLose'),
            dataIndex: 'winLoss',
            width: '25%',
            render: (_, { winLoss }) => $f(winLoss)
          },
        ]}
        pagination={false}
      />
    </Card>
  )
}